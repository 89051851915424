.container {
    margin: 0px 0px;
  }

.ui-primary-input::-webkit-file-upload-button {
  visibility: hidden;
  width:0px
}
.ui-primary-input::before {
  content: 'Upload Excel File';
  display: inline-block;
  background-color: #1237af;
  color: white;
  padding: 15px 32px;
  outline: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.ui-primary-input:hover::before {
  border-color: black;
}
.ui-primary-input:active::before {
  background: -webkit-linear-gradient(top, #1237af, #1237af);
}

  
  input {
    margin: 8px;
    width: 400px;
  }

  input[type='file'] {
    margin: 8px;
    width: 205px;
  }
  
  label {
    padding: 8px;
    color: white;
    background-color: #da7d13;
  }

  button {
    background-color: #1237af;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .title {
    margin-top: 10px;
  }
  table, th, td {
    border:1px solid black;
  }

  .centered {
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
  }